import { CountUp } from './lib/countup.js';

const doc = document;

// initialize AOS
AOS.init({
  duration: 250
});

// Toggle Mobile Navigation
if (doc.querySelector('.js-nav-toggle')) {
  doc.querySelector('.js-nav-toggle').addEventListener('click', function() {
    doc.querySelector('.js-mobile-navigation').classList.add('is-active');
  });
}

if (doc.querySelector('.js-close-mobile-nav')) {
  doc
    .querySelector('.js-close-mobile-nav')
    .addEventListener('click', function() {
      doc.querySelector('.js-mobile-navigation').classList.remove('is-active');
    });
}

// primary nav hover
doc.querySelectorAll('.c-site-navigation > li').forEach((navItem) => {
  navItem.addEventListener('mouseenter', function() {
    var clonedAd = doc.querySelector('.js-site-navigation-ad').cloneNode(true);
    clonedAd.classList.add('-is-visible');
    this.querySelector('.sub-menu').appendChild(clonedAd); // .classList.add('-is-hovered');
  })
  navItem.addEventListener('mouseleave', function() {
    this.querySelector('.sub-menu .js-site-navigation-ad').remove(); // .classList.add('-is-hovered');
  })
})

if( doc.querySelector('.js-slide-up-banner')){
// close site banner
doc.addEventListener('aos:in:slide-up-banner', () => {
    doc.querySelector('.js-slide-up-banner').classList.add('-is-active');
});

doc.querySelector('.js-close-slide-up-banner').addEventListener('click', () => {
  doc.querySelector('.js-slide-up-banner').classList.remove('-is-active');
});
}


var navSubMenuItems = doc.querySelectorAll(
  '.js-mobile-navigation nav > li.menu-item-has-children',
);

for (var i = 0; i < navSubMenuItems.length; i++) {
  // create the toolbar on top of mobile menu sub nav
  var subMenuToolbar = doc.createElement('li');
  subMenuToolbar.classList.add('c-mobile-navigation__sub-menu-toolbar');

  // create and add the back button to toolbar
  var subMenuToolbar__back_btn = doc.createElement('div');
  subMenuToolbar__back_btn.classList.add(
    'c-mobile-navigation__sub-menu-toolbar__back-btn',
  );

  subMenuToolbar.appendChild(subMenuToolbar__back_btn);

  subMenuToolbar__back_btn.addEventListener('click', function(e) {
    e.stopPropagation();
    this.parentNode.parentNode.classList.remove('is-active');
  });

  // create and add the heading to toolbar
  var subMenuToolbar__heading = doc.createElement('div');
  var subMenuHeadingText = doc.createTextNode(
    navSubMenuItems[i].firstElementChild.textContent,
  );
  subMenuToolbar__heading.classList.add(
    'c-mobile-navigation__sub-menu-toolbar__heading',
  );
  subMenuToolbar__heading.appendChild(subMenuHeadingText);

  subMenuToolbar.appendChild(subMenuToolbar__heading);

  // create and add the close button to toolbar
  var subMenuToolbar__close_btn = doc.createElement('div');
  subMenuToolbar__close_btn.classList.add(
    'c-mobile-navigation__sub-menu-toolbar__close-btn',
  );

  subMenuToolbar.appendChild(subMenuToolbar__close_btn);

  subMenuToolbar__close_btn.addEventListener('click', function(e) {
    e.stopPropagation();
    // close sub menu
    this.parentNode.parentNode.classList.remove('is-active');
    // close mobile nav
    this.parentNode.parentNode.parentNode.parentNode.parentNode.classList.remove(
      'is-active',
    );
  });

  // add the toolbar to the top of subnav
  navSubMenuItems[i].childNodes[2].insertBefore(
    subMenuToolbar,
    navSubMenuItems[i].childNodes[2].firstChild,
  );

  // listen for clicks on a mobile menu li that has a subnav
  navSubMenuItems[i].addEventListener('click', function() {
    this.childNodes[2].classList.add('is-active');
  });
}

// Count up animations
// Page: The Problem
document.addEventListener('aos:in:problem-count-up', function() {
  const problemCountUp = new CountUp(
    doc.querySelector('.js-count-up'),
    parseInt(doc.querySelector('.js-count-up').getAttribute('data-value')),
  );
  problemCountUp.start();
});

// Page: Stars
document.addEventListener('aos:in:stars-graph-1', function() {
  const starsGraph1 = new CountUp(
    doc.querySelector('.js-graph-1'),
    doc.querySelector('.js-graph-1').getAttribute('data-value'),
  );
  starsGraph1.start();
});

document.addEventListener('aos:in:stars-graph-2', function() {
  const starsGraph2 = new CountUp(
    doc.querySelector('.js-graph-2'),
    doc.querySelector('.js-graph-2').getAttribute('data-value'),
  );
  starsGraph2.start();
});

document.addEventListener('aos:in:stars-graph-3', function() {
  const starsGraph3 = new CountUp(
    doc.querySelector('.js-graph-3'),
    doc.querySelector('.js-graph-3').getAttribute('data-value'),
  );
  starsGraph3.start();
});

// Close Announcement
if (doc.querySelector('.js-close-announcement')) {
  doc
    .querySelector('.js-close-announcement')
    .addEventListener('click', function() {
      doc.querySelector('.js-announcement').classList.add('-is-hidden');
    });
}

// Gallery Toggles
if (doc.querySelector('.js-gallery-toggle')) {
  doc.querySelectorAll('.js-gallery-toggle').forEach((toggle) => {
    toggle.addEventListener('click', function() {
      this.classList.toggle('-is-active');
      this.parentNode.previousElementSibling.classList.toggle('-is-active');
    });
  });
}

if (doc.querySelector('.js-gallery-card-coming-soon-button')) {
  doc
    .querySelectorAll('.js-gallery-card-coming-soon-button')
    .forEach((button) => {
      button.addEventListener('click', () => {
        doc
          .querySelector('.js-slide-up-form')
          .classList.add('-is-active', '-highlight-me');

        doc.querySelector('.js-slide-up-form input[type="email"]').focus();

        setTimeout(() => {
          doc
            .querySelector('.js-slide-up-form')
            .classList.remove('-highlight-me');
        }, 1000);
      });
    });
}

// Gallery Slide Up Form
doc.addEventListener('aos:in:slide-up-form', () => {
  if (
    !doc
      .querySelector('.js-slide-up-form')
      .classList.contains('-permanently-closed')
  )
    doc.querySelector('.js-slide-up-form').classList.add('-is-active');
});

if (doc.querySelector('.js-close-slide-up-form')) {
  doc.querySelector('.js-close-slide-up-form').addEventListener('click', () => {
    doc.querySelector('.js-slide-up-form').classList.remove('-is-active');
    doc.querySelector('.js-slide-up-form').classList.add('-permanently-closed');
  });
}


// Stellarworx multi step form
if(doc.querySelector('.js-multi-step-form-button-step-2')){
  doc.querySelector('.js-multi-step-form-button-step-2').addEventListener('click', function(){

    if(this.dataset.type && (doc.querySelector('.multi-step-form-email').value !== '')) {
      doc.querySelector('.multi-step-form__step.-step-1').classList.remove('-is-active');
      doc.querySelector(`.multi-step-form__step.-step-2[data-group="${this.dataset.type}"]`).classList.add('-is-active');
      console.log(doc.querySelector('.multi-step-form-email').value);


      doc.querySelectorAll('[name="email"]').forEach(email => {
        email.value = doc.querySelector('.multi-step-form-email').value;
      }) 
    } else {
      doc.querySelector('.js-hiring-need-label').classList.add('-is-active')
    }
  });
}

doc.querySelectorAll('.js-hiring-need-button').forEach(hiringNeed => {
  hiringNeed.addEventListener('change', function() {
    doc.querySelector('.js-multi-step-form-button-step-2').setAttribute('data-type', this.value);
  });
})


// STARs Profiles overlay
if (doc.querySelector('.js-stars-profile-toggle')) {
  doc.querySelectorAll('.js-stars-profile-toggle').forEach((starsToggle) => {
    starsToggle.addEventListener('click', function() {
      // remove current active toggle
      if (doc.querySelector('.js-stars-profile-toggle.-is-active')) {
        doc
          .querySelector('.js-stars-profile-toggle.-is-active')
          .classList.remove('-is-active');
      }

      this.classList.add('-is-active');

      // populate overlay fields
      doc.querySelector('.js-stars-overlay-name').innerHTML = this.getAttribute(
        'data-name',
      );
      doc.querySelector(
        '.js-stars-overlay-title',
      ).innerHTML = this.getAttribute('data-title');
      doc.querySelector(
        '.js-stars-overlay-intro',
      ).innerHTML = this.getAttribute('data-intro');

      doc.querySelector('.js-stars-overlay-bio').innerHTML = this.getAttribute(
        'data-bio',
      );
      doc.querySelector(
        '.js-stars-overlay-photo',
      ).style = `background-image: url(${this.getAttribute('data-photo')})`;

      // show overlay
      doc
        .querySelector('.js-stars-profiles-overlay')
        .classList.add('-is-active');
    });
  });

  // pre-click a STAR if the url has the stars parameter
  if (getParameterByName('star')) {
    if (
      doc.querySelector(
        `.js-stars-profile-toggle[data-name="${getParameterByName('star')}"]`,
      )
    ) {
      doc
        .querySelector(
          `.js-stars-profile-toggle[data-name="${getParameterByName('star')}"]`,
        )
        .click();
    }
  }

  doc
    .querySelectorAll('.js-stars-profiles-overlay__btn')
    .forEach((overlayBtn) => {
      overlayBtn.addEventListener('click', function() {
        // go backwards
        if (this.getAttribute('data-direction') === 'previous') {
          // if there is a previous star, go backwards - else go to end of list
          if (
            doc.querySelector('.js-stars-profile-toggle.-is-active')
              .previousElementSibling
          ) {
            doc
              .querySelector('.js-stars-profile-toggle.-is-active')
              .previousElementSibling.click();
          } else {
            doc.querySelector('.js-stars-profile-toggle:last-child').click();
          }
        } else {
          // go forwards
          if (
            // if there is a next star, go forwards - else go to beginning of list
            doc.querySelector('.js-stars-profile-toggle.-is-active')
              .nextElementSibling
          ) {
            doc
              .querySelector('.js-stars-profile-toggle.-is-active')
              .nextElementSibling.click();
          } else {
            doc.querySelector('.js-stars-profile-toggle:first-child').click();
          }
        }
      });
    });

  // close overlay
  doc.querySelector('.js-stars-profile-close').addEventListener('click', () => {
    doc
      .querySelector('.js-stars-profiles-overlay')
      .classList.remove('-is-active');
  });
}

// Anchor links
var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

const anchorLinks = doc.querySelectorAll('a[href^="#"]');

if (!isIE11) {
  for (let i = 0; i < anchorLinks.length; i++) {
    anchorLinks[i].addEventListener('click', scrollAnchors);
  }
}

function scrollAnchors(e, respond = null) {
  const distanceToTop = (el) => Math.floor(el.getBoundingClientRect().top);
  e.preventDefault();
  var targetID = respond
    ? respond.getAttribute('href')
    : this.getAttribute('href');
  const targetAnchor = document.querySelector(targetID);
  if (!targetAnchor) return;
  const originalTop = distanceToTop(targetAnchor);
  window.scrollBy({ top: originalTop, left: 0, behavior: 'smooth' });
  const checkIfDone = setInterval(function() {
    const atBottom =
      window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2;
    if (distanceToTop(targetAnchor) === 0 || atBottom) {
      targetAnchor.tabIndex = '-1';
      targetAnchor.focus();
      window.history.pushState('', '', targetID);
      clearInterval(checkIfDone);
    }
  }, 100);
}

// requestAnimationFrame() shim by Paul Irish
// http://paulirish.com/2011/requestanimationframe-for-smart-animating/
window.requestAnimFrame = (function() {
  return (
    window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.oRequestAnimationFrame ||
    window.msRequestAnimationFrame ||
    function(/* function */ callback, /* DOMElement */ element) {
      window.setTimeout(callback, 1000 / 60);
    }
  );
})();

/**
 * Behaves the same as setTimeout except uses requestAnimationFrame() where possible for better performance
 * @param {function} fn The callback function
 * @param {int} delay The delay in milliseconds
 */

window.requestTimeout = function(fn, delay) {
  if (
    !window.requestAnimationFrame &&
    !window.webkitRequestAnimationFrame &&
    !(
      window.mozRequestAnimationFrame && window.mozCancelRequestAnimationFrame
    ) && // Firefox 5 ships without cancel support
    !window.oRequestAnimationFrame &&
    !window.msRequestAnimationFrame
  )
    return window.setTimeout(fn, delay);

  var start = new Date().getTime(),
    handle = new Object();

  function loop() {
    var current = new Date().getTime(),
      delta = current - start;

    delta >= delay ? fn.call() : (handle.value = requestAnimFrame(loop));
  }

  handle.value = requestAnimFrame(loop);
  return handle;
};

/**
 * Behaves the same as clearTimeout except uses cancelRequestAnimationFrame() where possible for better performance
 * @param {int|object} fn The callback function
 */
window.clearRequestTimeout = function(handle) {
  window.cancelAnimationFrame
    ? window.cancelAnimationFrame(handle.value)
    : window.webkitCancelAnimationFrame
    ? window.webkitCancelAnimationFrame(handle.value)
    : window.webkitCancelRequestAnimationFrame
    ? window.webkitCancelRequestAnimationFrame(
        handle.value,
      ) /* Support for legacy API */
    : window.mozCancelRequestAnimationFrame
    ? window.mozCancelRequestAnimationFrame(handle.value)
    : window.oCancelRequestAnimationFrame
    ? window.oCancelRequestAnimationFrame(handle.value)
    : window.msCancelRequestAnimationFrame
    ? window.msCancelRequestAnimationFrame(handle.value)
    : clearTimeout(handle);
};

function getParameterByName(name) {
  var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}


